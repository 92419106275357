import styled from "styled-components";

export const Wrapper = styled.div`
width:100%;
max-width: var(--superMediumWidth);
margin:auto;
margin-top:20px;
padding: var(--padding-left-right);
`;

export const PlannerNavigation = styled.div`
width:100%;
max-width: var(--superMediumWidth);
margin:auto;
border-top: 1px solid black;

.active {
    border-bottom:none;
}

`;

export const PlannerNavigationTab = styled.div`
width:33.3%;
max-width: var(--superMediumWidth);
padding-top:10px;
padding-bottom:10px;
display:inline-block;
border-bottom:1px solid black;
border-left:1px solid black;
border-right:1px solid black;
text-align:center;
`;

export const PlannerContent = styled.div`
width:100%;
max-width: var(--superMediumWidth);
margin:auto;
margin-top:40px;
min-height:100px;

    .row {
        display:block;
        margin-top:5px;
        margin-bottom:5px;
    }

    .rowHead {
        display:block;
    }
    .rowCircle {
        display:inline-block;
        vertical-align:top;
        width:20px;
        height:20px;
        border-radius:100%;
        border:1px solid;
        border-color: var(--color-border-5);
        background: var(--color-bg-2);
    }
    .rowTime {
        display:inline-block;
        margin-left:10px;
        vertical-align:top;
    }
    .rowTitle {
        display:inline-block;
        margin-left:10px;
        vertical-align:top;
    }

    .rowBody {
        display:block;
        margin-left:8px;
        border-left:5px solid;
        border-color: var(--color-border-1);
    }
    .rowTask {
        display:block;
        width:max-content;
        border: 1px solid;
        border-color: var(--color-border-4);
        border-radius:6px;
        margin-top:5px;
        margin-left:15px;
        padding:6px;
        background: var(--color-bg-2);
        box-shadow: var(--box-shadow-1);
    }

`;

export const PlannerContentToday = styled.div`
width:100%;
max-width: var(--superMediumWidth);
margin:auto;
margin-top:10px;
min-height:100px;

`;

export const PlannerContentWeek = styled.div`
width:100%;
max-width: var(--superMediumWidth);
margin:auto;
margin-top:10px;
min-height:100px;

`;

export const PlannerContentMonth = styled.div`
width:100%;
max-width: var(--superMediumWidth);
margin:auto;
margin-top:10px;
min-height:100px;
`;