import styled from 'styled-components';

export const Wrapper = styled.div`
margin: var(--margin-top-bottom);
display:inline-block;

animation: animatePostInfo 0.5s;
@keyframes animatePostInfo {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
`;

export const HashtagWrapper = styled.div`
display:inline-block;
vertical-align:top;
`;

export const MainHashtag = styled.div`
    cursor:pointer;
    display: inline-block;
    margin-left:6px;
    padding: 6px;
    border-radius: 12px;
    background: var(--color-bg-2);
    box-shadow: var(--box-shadow-1);
    overflow:hidden;
    color: var(--color-5);
    width:140px;


    article {
        width: 100%;
    }
    i {
        color: var(--color-4) !important;
    }
    .body {
        display:block;
    }
    .image {
        margin:auto;
        margin-top:6px;
        padding:0px;
        width:100%;
        display:block;
        border-radius:12px;
    }
    .title {
    margin-top:6px;
    text-align:center;
    border: none;
    border-radius: 6px;
    height:auto;
    line-height:1.25em;
    min-height:2.5em;
    color:  var(--color-5) !important;
    font-size: var(--fontSmall);
    font-weight: 600;
    display:block;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
    }
    .text {
    display:none !important;
    width: 100%;
    border: none;
    height:auto;
    color: var(--color-4) !important;
    }
    .text p {
    font-size: var(--fontSmall);
    font-weight: normal;
    word-break: break-word;
    color: var(--color-5) !important;
    }
`;

export const SubHashtag = styled.div`
cursor:pointer;
display: inline-block;
margin-left:6px;
padding: 12px;
border-radius: 12px;
background: var(--color-bg-2);
box-shadow: var(--box-shadow-1);
overflow:hidden;
color: var(--color-5);
width:max-content;

article {
    width: 100%;
}
.body {
    display:block;
}
.image {
    margin:auto;
    margin-top:6px;
    padding:0px;
    width:100px;
    height:100px;
    display:block;
}
.title {
text-align:center;
border: none;
border-radius: 6px;
height:auto;
color:  var(--color-5) !important;
font-size: var(--fontSmall);
font-weight: 600;
display:block;
overflow: hidden;
text-overflow: ellipsis;
display: -webkit-box;
-webkit-line-clamp: 1;
line-clamp: 1;
-webkit-box-orient: vertical;
}
`;

export const Product = styled.div`
    cursor:pointer;
    display: inline-block;
    padding: 6px;
    border-radius: 12px;
    background: var(--color-bg-2);
    box-shadow: var(--box-shadow-1);
    overflow:hidden;
    color: var(--color-5);
    width:130px;
    margin-left:6px;

    article {
        width: 100%;
    }
    .header {
        display:block;
    }
        .imageShop {
            display:inline-block;
            width:30px;
            height:30px;
            box-shadow: var(--box-shadow-1);
        }
        .moderation {
            display:inline-block;
            max-width:10%;
            float:right;
        }
    .body {
        display:block;
    }
        .image {
            margin:auto;
            margin-top:6px;
            padding:0px;
            width:100%;
            display:block;
        }

        .price {
            width: 100%;
            border: none;
            padding: 0px;
            margin: var(--margin-top-bottom);
            height:auto;
            font-size: var(--fontSmall) !important;
        }
        .date {
            display:inline-block !important;
            margin-top:6px;
            font-size: var(--fontSuperSuperSmall) !important;
            font-weight: 500 !important;
            
            .icon {
                display:inline-block;
                color:var(--color-1) !important;
            }
        }
        .title {
            margin:auto;
            text-align:left;
            border: none;
            margin: var(--margin-top-bottom);
            border-radius: 6px;
            height:auto;
            color:  var(--color-5) !important;
            font-size: var(--fontSmall);
            font-weight: 600;
            display:block;
            overflow: hidden;
        }
        .text {
            display:none !important;
            width: 100%;
            border: none;
            padding:0px;
            height:auto;
            color: var(--color-4) !important;
        }
        .text p {
            font-size: var(--fontSmall);
            font-weight: normal;
            word-break: break-word;
            color: var(--color-5) !important;
        }

    .post-answer-vote {
    display:none !important;
    display:block;
    text-align:right;
    color: var(--color-4);
    margin-top:12px;
    padding-top:12px;
    border-top: 3px solid;
    border-color: var(--color-border-3);

        div {
            float:none;
            margin:0 auto;
        }
    }
    
    .post-answer-vote-like {
    cursor:pointer;
    font-size: var(--fontMed);
    }
    .post-answer-vote-dislike {
    cursor:pointer;
    font-size: var(--fontMed);
    }
    .post-answer-vote-like:hover, .post-answer-vote-dislike:hover {
    opacity:0.75 !important;
    }
    .post-answer-vote-amount {
    font-size: var(--fontSmall);
    font-weight: 600;
    margin-right:30px;
    padding:6px;
    }
`;

export const Content = styled.div`
    cursor:pointer;
    display: inline-block;
    margin-left:6px;
    border-radius: 12px;
    background: var(--color-bg-2);
    box-shadow: var(--box-shadow-1);
    overflow:hidden;
    color: var(--color-5);
    width:max-content;

    article {
        width: 100%;
    }

    i {
        color: var(--color-4) !important;
    }

    .post-wrapper {
        a {
            color: var(--color-5);
        }
        :hover, :focus, :active {
            cursor:pointer;
            background: var(--color-bg-3);
        }
    }

    .subHashtag {
        
    }

    .postHeader {
        display:block;
    }
    .postImageShop {
        display:inline-block;
        width:25px;
        height:25px;
        box-shadow: var(--box-shadow-1);
    }
    .postModeration {
        display:inline-block;
        max-width:10%;
        float:right;
    }
    .postBody {
        display:block;
    }
    .postImage {
        margin:auto;
        width:100px;
        height:100px;

        display:block;
    }

    .post-title {
    margin:auto;
    text-align:center;
    border: none;
    margin: var(--margin-top-bottom);
    border-radius: 6px;
    height:auto;
    color:  var(--color-5) !important;
    font-size: var(--fontSuperSmall);
    font-weight: 600;
    max-width:90%;
    display:block;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
    }

    .subHashtagTitle {
        margin:auto;
        text-align:center;
        border: none;
        margin: var(--margin-top-bottom);
        border-radius: 6px;
        height:auto;
        color:  var(--color-5) !important;
        font-size: var(--fontSuperSmall);
        font-weight: 600;

        display:block;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        line-clamp: 1;
        -webkit-box-orient: vertical;
    }

    .post-text {
    display:none !important;
    width: 100%;
    border: none;
    padding:0px;
    padding-bottom: 12px;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 6px;
    border-bottom-left-radius: 6px;
    height:auto;
    color: var(--color-4) !important;
    }
    .post-text p {
    font-size: var(--fontSmall);
    font-weight: normal;
    word-break: break-word;
    color: var(--color-5) !important;
    }
    .post-answer {
    width: 100%;
    border: none;
    padding: 0px;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 6px;
    border-bottom-left-radius: 6px;
    height:auto;
    }
    .post-answer-profile {
    padding-top:0px !important;
    }
    .post-answer p {
    font-size: var(--fontSuperSmall);
    font-weight: 500;
    color: var(--color-4) !important;
    word-break: break-word;
    white-space: pre-line;
    display:inline;
    margin-right:5px;
    }
    .post-answer-profile p {
    font-size: 16px;
    font-weight: normal;
    word-break: break-word;
    }
    .answerDate {
        display:inline-block !important;
        padding-top: 10px;
        font-size: var(--fontSuperSuperSmall) !important;
        font-weight: 500 !important;
        
        .icon {
            display:inline-block;
            color:var(--color-1) !important;
        }
    }

    .post-answer-vote {
    display:none !important;
    display:block;
    text-align:right;
    color: var(--color-4);
    margin-top:12px;
    padding-top:12px;
    border-top: 3px solid;
    border-color: var(--color-border-3);

        div {
            float:none;
            margin:0 auto;
        }
    }
    
    .post-answer-vote-like {
    cursor:pointer;
    font-size: var(--fontMed);
    }
    .post-answer-vote-dislike {
    cursor:pointer;
    font-size: var(--fontMed);
    }
    .post-answer-vote-like:hover, .post-answer-vote-dislike:hover {
    opacity:0.75 !important;
    }
    .post-answer-vote-amount {
    font-size: var(--fontSmall);
    font-weight: 600;
    margin-right:30px;
    padding:6px;
    }
`;