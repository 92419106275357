import React, { useState } from 'react';
//Styles
import { 
        WrapperHorizontal, ButtonHorizontal, AmountHorizontal,
        WrapperVertical, ButtonVertical, AmountVertical
       } from './IncrementCounter.styles';



const IncrementCounter = ({ value, getNumber, horizontal, vertical, min }) => {

    if(!min) {
      min = 0;
    }

    // Set the initial count state to zero, 0
    const [count, setCount] = useState(Number(value));
  
    // Create handleIncrement event handler
    const handleIncrement = () => {
      let newCount = count + 1;
      setCount(newCount);
      getNumber(newCount);
    }; 
  
    //Create handleDecrement event handler
    const handleDecrement = () => {
      //minimum 0
      let newCount = Math.max(min,count - 1);
      setCount(newCount);
      getNumber(newCount);
    };
    
    if(horizontal === true) {
      return (
          <WrapperHorizontal>
              <ButtonHorizontal onClick={handleDecrement}><i className='fa-solid fa-minus'></i></ButtonHorizontal>
              <AmountHorizontal>{count}</AmountHorizontal>
              <ButtonHorizontal onClick={handleIncrement}><i className='fa-solid fa-plus'></i></ButtonHorizontal>
              {/*<Button onClick={() => setCount(0)}>Reset</Button>*/}
          </WrapperHorizontal>
      );
    }else if(vertical === true) {
      return (
        <WrapperVertical>
            <ButtonVertical onClick={handleIncrement}><i className='fa-solid fa-plus'></i></ButtonVertical>
            <AmountVertical>{count}</AmountVertical>
            <ButtonVertical onClick={handleDecrement}><i className='fa-solid fa-minus'></i></ButtonVertical>
            {/*<Button onClick={() => setCount(0)}>Reset</Button>*/}
        </WrapperVertical>
      );
    }
    else
    {
      return (
        <></>
      );
    }

  }
  
  export default IncrementCounter;