import React, { useState } from 'react';
import { useNavigate, Link, useParams } from 'react-router-dom';
//Mobile Check
import {isMobile} from 'react-device-detect';
//Functions
import UserFunctions from './UserFunctions';
// Components
import Header from './Header';
import Wrapper from './Wrapper'
import Footer from './Footer';
import Hashtag from './Hashtag';
//Styles
import { Wrapper as InventoryWrapper, InventoryNavigation, InventoryNavigationTab, InventoryContent, InventoryContentShop } from './Inventory/Inventory.styles';
//Hook
import { useInventoryFetch } from '../hooks/useInventoryFetch';


const Inventory = () => {

    const {
        state
    } = useInventoryFetch(UserFunctions.getSessionHash());

    const [inventoryNavigationTab, setInventoryNavigationTab] = useState('');

    if(state.results[0]) {
        var hashtags = state.results[0].hashtags;
    }
    return (
        <>
            <Header userName={UserFunctions.getUserName()} />
            <Wrapper>
                <InventoryWrapper>
                    <InventoryNavigation>
                        <InventoryNavigationTab className={(inventoryNavigationTab == 'Kaufland') ? `active` :null} onClick={() => setInventoryNavigationTab('Kaufland')}>Kaufland</InventoryNavigationTab>
                        <InventoryNavigationTab className={(inventoryNavigationTab == 'Lidl') ? `active` :null} onClick={() => setInventoryNavigationTab('Lidl')}>Lidl</InventoryNavigationTab>
                        <InventoryNavigationTab className={(inventoryNavigationTab == 'Edeka') ? `active` :null} onClick={() => setInventoryNavigationTab('Edeka')}>Edeka</InventoryNavigationTab>
                    </InventoryNavigation>
                    <InventoryContent>
                        {
                            (inventoryNavigationTab == 'Kaufland') ?
                                <InventoryContentShop>

                                        {
                                            (hashtags) ?
                                                Object.keys(hashtags).map((a) => (
                                                    <div className='row'>
                                                        <div className='rowHead'>
                                                            <div className='rowCircle'></div>
                                                            <div className='rowTitle'>
                                                                <Link to={`/!${encodeURIComponent(hashtags[a].hashtag)}`}>
                                                                    <Hashtag key={hashtags[a].id}
                                                                    active={ `${(hashtags[a].hashtag == 'placeholder') ? true : false}`}
                                                                    text={hashtags[a].hashtag}
                                                                    hidden={ `${(hashtags[a].hidden == true) ? true : false}`}
                                                                    disabled={ `${(hashtags[a].disabled == true) ? true : false}`} />
                                                                </Link>
                                                            </div>
                                                        </div>
                                                        <div className='rowBody'>
                                                            <div className='rowTask'>Omega 3 Eier 12er | 2.99&euro;</div>
                                                        </div>
                                                    </div>
                                                ))
                                            :null
                                        }

                                </InventoryContentShop>
                            :null
                        }
                        {
                            (inventoryNavigationTab == 'Lidl') ?
                                <InventoryContentShop>
                                    <div className='row'>
                                        <div className='rowHead'>
                                            <div className='rowCircle'></div>

                                            <div className='rowTitle'>Ei</div>
                                        </div>
                                        <div className='rowBody'>
                                            <div className='rowTask'>Omega 3 Eier 12er | 2.99&euro;</div>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='rowHead'>
                                            <div className='rowCircle'></div>

                                            <div className='rowTitle'>Milch</div>
                                        </div>
                                        <div className='rowBody'>
                                            <div className='rowTask'>alporo Not Milk 3,9% | 1,50&euro;</div>
                                            <div className='rowTask'>alporo Not Milk 1,5% | 1,50&euro;</div>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='rowHead'>
                                            <div className='rowCircle'></div>

                                            <div className='rowTitle'>Avocado</div>
                                        </div>
                                        <div className='rowBody'>
                                            <div className='rowTask'>Avocado Einzeln | 1,69&euro;</div>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='rowHead'>
                                            <div className='rowCircle'></div>

                                            <div className='rowTitle'>Pasta</div>
                                        </div>
                                        <div className='rowBody'>
                                            <div className='rowTask'>Dinkel Pasta 500g | 0,99&euro;</div>
                                        </div>
                                    </div>
                                </InventoryContentShop>
                            :null
                        }
                        {
                            (inventoryNavigationTab == 'Edeka') ?
                                <InventoryContentShop>
                                    <div className='row'>
                                        <div className='rowHead'>
                                            <div className='rowCircle'></div>

                                            <div className='rowTitle'>Ei</div>
                                        </div>
                                        <div className='rowBody'>
                                            <div className='rowTask'>Omega 3 Eier 12er | 2.99&euro;</div>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='rowHead'>
                                            <div className='rowCircle'></div>

                                            <div className='rowTitle'>Milch</div>
                                        </div>
                                        <div className='rowBody'>
                                            <div className='rowTask'>alporo Not Milk 3,9% | 1,50&euro;</div>
                                            <div className='rowTask'>alporo Not Milk 1,5% | 1,50&euro;</div>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='rowHead'>
                                            <div className='rowCircle'></div>

                                            <div className='rowTitle'>Avocado</div>
                                        </div>
                                        <div className='rowBody'>
                                            <div className='rowTask'>Avocado Einzeln | 1,69&euro;</div>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='rowHead'>
                                            <div className='rowCircle'></div>

                                            <div className='rowTitle'>Pasta</div>
                                        </div>
                                        <div className='rowBody'>
                                            <div className='rowTask'>Dinkel Pasta 500g | 0,99&euro;</div>
                                        </div>
                                    </div>
                                </InventoryContentShop>
                            :null
                        }
                    </InventoryContent>
                        {
                            state.results.map(row => (
                                <>

                                </>
                            ))
                        }


                </InventoryWrapper>
            </Wrapper>
            {
                (!isMobile) ? <Footer /> :null
            }
        </>
    )
};

export default Inventory;