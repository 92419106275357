import styled from "styled-components";

export const Wrapper = styled.div`
margin: 0 auto;
width:100%;
max-width: var(--smallWidth);


.post-wrapper {
    margin: 12px 0;
}
`;