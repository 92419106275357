import styled from 'styled-components';

export const Wrapper = styled.input`
    display:block;
    background: var(--color-bg-2);
    color: var(--color-5);
    border:1px solid;
    border-color: var(--color-border-5);
    border-radius: 12px;
    padding: var(--padding-all);
    font-size: var(--fontMed);
    transition: all 0.3s;
    outline: none;
    cursor: pointer;

    :hover {
        opacity: 0.8;
    }
`;