import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
//Package
import disableScroll from 'disable-scroll';
import {isMobile} from 'react-device-detect';
import TextareaAutosize from 'react-textarea-autosize';
//API
import API from '../../API';
//Components
import GetText from '../GetText';
import Spinner from '../Spinner';
import Textarea from '../Textarea';
import Button from '../Button';
import Hashtag from '../Hashtag';
//Styles
import { Wrapper, QaWrapper, QaContent, QaButton, QaButtonWrapper } from './CreatePost.style';
//Hook
import { useHashtagsFetch } from '../../hooks/useHashtagsFetch';
import { useShopsFetch } from '../../hooks/useShopsFetch';
import { useUnitsFetch } from '../../hooks/useUnitsFetch';
import UserFunctions from '../UserFunctions';

const CreatePost = ({ value, userHash }) => {

    /* Hashtag Stuff */
    const [hashtagObject, setHashtagObject] = useState('');
    const [hashtagObjectCounter, setHashtagObjectCounter] = useState();
    const [hashtagActive, setHashtagActive] = useState();

    const [hashtagMinAmount, setHashtagMinAmount] = useState();
    const [hashtagMaxAmount, setHashtagMaxAmount] = useState();


    const handleCategoryClick = e => {
        const objectCounter = e.currentTarget.name; //objectCounter

        if(objectCounter) {

            if(hashtagObject[objectCounter]) {

                if(hashtagObject[objectCounter].active) {

                    setHashtagObjectCounter(objectCounter);
                    setHashtagActive(false);
                }
                else
                {
                    setHashtagObjectCounter(objectCounter);
                    setHashtagActive(true);
                }
            }

        }
        setHashtagObject(null);
        setHashtagObject(hashtagObject);

    };

    const {
        state: dataHashtags,
        loading: hashtagLoading,
        error: hashtagError,
        subHashtag,
        setSubHashtag
    } = useHashtagsFetch();

    if(subHashtag === false) {
        setSubHashtag(true); //default
    }

    if(dataHashtags[0] && !hashtagObject[0]) {
        setHashtagObject(dataHashtags);
    }
    
    if(hashtagObject) {

        if(hashtagObjectCounter) {
            hashtagObject[hashtagObjectCounter].active = hashtagActive;
        }

    }
    /* Hashtag Stuff End */

    /* Shop Stuff */
    const [shopObject, setShopObject] = useState('');
    const [shopObjectCounter, setShopObjectCounter] = useState();
    const [shopActive, setShopActive] = useState();

    const [shopMinAmount, setShopMinAmount] = useState(1);
    const [shopMaxAmount, setShopMaxAmount] = useState(1);


    const handleShopClick = e => {
        const objectCounter = e.currentTarget.name; //objectCounter

        if(objectCounter) {

            if(shopObject[objectCounter]) {

                if(shopObject[objectCounter].active) {

                    setShopObjectCounter(objectCounter);
                    setShopActive(false);
                }
                else
                {
                    setShopObjectCounter(objectCounter);
                    setShopActive(true);
                }
            }

        }
        setShopObject(null);
        setShopObject(shopObject);

    };

    const {
        state: dataShops,
        loading: shopLoading,
        error: shopError
    } = useShopsFetch();


    if(dataShops[0] && !shopObject[0]) {
        setShopObject(dataShops);
    }
    
    if(shopObject) {

        if(shopObjectCounter) {
            shopObject[shopObjectCounter].active = shopActive;
        }

    }
    /* Shop Stuff End */

    /* Unit Stuff */
    const [unitObject, setUnitObject] = useState('');
    const [unitObjectCounter, setUnitObjectCounter] = useState();
    const [unitActive, setUnitActive] = useState();

    const [unitMinAmount, setUnitMinAmount] = useState(1);
    const [unitMaxAmount, setUnitMaxAmount] = useState(1);


    const handleUnitClick = e => {
        const objectCounter = e.currentTarget.name; //objectCounter

        if(objectCounter) {

            if(unitObject[objectCounter]) {

                if(unitObject[objectCounter].active) {

                    setUnitObjectCounter(objectCounter);
                    setUnitActive(false);
                }
                else
                {
                    setUnitObjectCounter(objectCounter);
                    setUnitActive(true);
                }
            }

        }
        setUnitObject(null);
        setUnitObject(unitObject);

    };

    const {
        state: dataUnits,
        loading: unitLoading,
        error: unitError
    } = useUnitsFetch();


    if(dataUnits[0] && !unitObject[0]) {
        setUnitObject(dataUnits);
    }
    
    if(unitObject) {

        if(unitObjectCounter) {
            unitObject[unitObjectCounter].active = unitActive;
        }

    }
    /* Unit Stuff End */

    /* Post Stuff */
    const [title, setTitle] = useState(' ');
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [loading, setLoading] = useState(false);
    const [step, setStep] = useState('start');

    const navigate = useNavigate();

    const handleInputPost = e => {
        const name = e.currentTarget.name;
        const value = e.currentTarget.value;

        if(name === 'postTextareaTitle') setTitle(value);
        
    };

    /* Handle Step Click */

    function handleStep(nextStep) {

        //Step 1
        if(nextStep == 'start') {
            //clear error message
            setErrorMessage(null);
            //Goto Next step
            setStep('start');
        }

        //Step 2
        if(nextStep == 'chooseHashtag') {
            /* title check */
            if(!title) {
                //error message
                setErrorMessage('postTitleEmpty');
            }
            else
            {
                //clear error message
                setErrorMessage(null);
                //Goto Next step
                setStep('chooseHashtag');
            }
            /* title check END */
        }

        //Step 3
        if(nextStep == 'chooseShop') {
            
            //Count selected hashtags
            function countHashtag(obj)
            {
                var count = 0;
                var countSelected = 0;
                for(var prop in obj)
                {
                    if(obj[count].active == true) {
                        countSelected++;
                    }
                    count++;
                }
                return countSelected;
            }

            var countHashtagCheck = countHashtag(hashtagObject);

            /* hashtag check */
            if(!countHashtagCheck) {
                //error message
                setErrorMessage('hashtagChoose');
            }
            else
            {
                //clear error message
                setErrorMessage(null);
                //Goto Next step
                setStep('chooseShop');
            }
            /* hashtag check END */
        }

        //Step 4
        if(nextStep == 'chooseUnit') {
            
            //Count selected hashtags
            function countShop(obj)
            {
                var count = 0;
                var countSelected = 0;
                for(var prop in obj)
                {
                    if(obj[count].active == true) {
                        countSelected++;
                    }
                    count++;
                }
                return countSelected;
            }

            var countShopCheck = countShop(shopObject);

            /* shop check */
            if(!countShopCheck) {
                //error message
                setErrorMessage('shopChoose');
            }
            else
            {
                //clear error message
                setErrorMessage(null);
                //Goto Next step
                setStep('chooseUnit');
            }
            /* hashtag check END */
        }

        //Step 5
        if(nextStep == 'lastPreview') {
            
            //Count selected hashtags
            function countUnit(obj)
            {
                var count = 0;
                var countSelected = 0;
                for(var prop in obj)
                {
                    if(obj[count].active == true) {
                        countSelected++;
                    }
                    count++;
                }
                return countSelected;
            }

            var countUnitCheck = countUnit(unitObject);

            /* hashtag check */
            if(!countUnitCheck) {
                //error message
                setErrorMessage('unitChoose');
            }
            else
            {
                //clear error message
                setErrorMessage(null);
                //Goto Next step
                setStep('lastPreview');
            }
            /* hashtag check END */
        }


        //Scroll up
        window.scrollTo(0, 0);
    }

    /* Handle Step Click END */


    /* mobile version */
    if((isMobile && UserFunctions.getUserId() == 1) || (isMobile && UserFunctions.getUserId() == 2)) {
        if(step == 'chooseHashtag') {
            //disableScroll.off();
        }
        else
        {
            window.scrollTo(0, 0);
            //disableScroll.on();
        }

        if(step == null) {
            //disableScroll.off();
        }
    }
    /* Mobile version end */

    const handleSubmitPost = async () => {
        if(title) {
            try {
                setLoading(true)
                setError(false);
                setHashtagMinAmount(null)
                setHashtagMaxAmount(null)
                setErrorMessage(null)
        
                /* check some things */
                    var newTitle = title;
                    /* check if title ends with space */

                        if(/\s$/.test(newTitle)) {
                            var newTitle = (newTitle.substring(0, newTitle.length - 1))
                        }
                    /* check if title ends with space End */

                    /* check if question mark is there */
                        /*
                        var check = newTitle.endsWith('?');

                        if(!check) {
                            var newTitle = newTitle+'?';
                        }
                        */
                    /* check if question mark is there End */
                    
                    /* setTitle after checks if something changed */
                    if(newTitle != title) {
                        setTitle(newTitle);
                    }
                    /* setTitle after checks if something changed End */
                /* check some things end */
                    const data = await API.createPost(newTitle, userHash, hashtagObject, shopObject, unitObject);
            
                    var postUrl = data.results[0].postUrl;
                    var errorMessage = data.results[0].error;

                    if(postUrl) { 
                        setLoading(false);
                        setStep(null);
                        navigate('/p/' + postUrl);
                    }

                    if(errorMessage) {
                        setLoading(false);
                        setErrorMessage(errorMessage)
                    }
                    if(data.results[0].hashtagMinAmount) {
                        setLoading(false);
                        setHashtagMinAmount(data.results[0].hashtagMinAmount)
                    }
                    if(data.results[0].hashtagMaxAmount) {
                        setLoading(false);
                        setHashtagMaxAmount(data.results[0].hashtagMaxAmount)
                    }
                    if(data.results[0].shopMinAmount) {
                        setLoading(false);
                        setHashtagMinAmount(data.results[0].shopMinAmount)
                    }
                    if(data.results[0].shopMaxAmount) {
                        setLoading(false);
                        setHashtagMaxAmount(data.results[0].shopMaxAmount)
                    }


            } catch (error) {
                setLoading(false);
                setError(true);
            }
        }
    };

    useEffect(() => {
        if(!loading) {
            if(title) {
                return;
            }
        }

    }, [title, value, loading]);

        /* Fill Title Field with Search Value */
        if(title == ' ') {
            setTitle(value);
        }
        /* Fill Title Field with Search Value End */
    /* Post Stuff End */

    if(loading) return <Spinner />
    if(error) return <Wrapper>Something went wrong...</Wrapper>

    return (

        <QaWrapper>
            <QaContent>
                {
                    (errorMessage) ?
                        <div className="QaText">
                            <GetText textName={errorMessage} textStyle={'default'} />
                            {
                                (hashtagMinAmount) ?
                                    <> <GetText textName={'minAmount'} textStyle={'default'} valueAfter={hashtagMinAmount} /> </>
                                :null
                            }
                            {
                                (hashtagMaxAmount) ?
                                    <> <GetText textName={'maxAmount'} textStyle={'default'} valueAfter={hashtagMaxAmount} /> </>
                                :null
                            }
                        </div>
                    :null
                }
                {
                    (step == 'start') ?
                        <>
                            <div className="QaTitle"><GetText textName='post_title' /></div>
                            <TextareaAutosize name="postTextareaTitle" className='QaTextarea' placeholder='...' value={title} onChange={handleInputPost} />
                            <QaButtonWrapper>
                                <QaButton onClick={() => {handleStep('chooseHashtag')} }>{<i className="fa-solid fa-circle-right"></i>}</QaButton>
                            </QaButtonWrapper>
                        </>
                    :null
                }
                {
                    (step == 'chooseHashtag') ?
                        <>
                            <div className="QaTitle"><GetText textName={'hashtagChoose'} /></div>
                            <div className="QaText">{title}</div>
                            <div className='QaHashtagWrapper'>
                                {
                                    (hashtagObject) ?
                                        Object.keys(hashtagObject).map((a) => (
                                            <Hashtag key={hashtagObject[a].hashtagId}
                                            active={ `${(hashtagObject[a].active == true) ? true : false}`}
                                            name={a} onClick={handleCategoryClick}
                                            text={hashtagObject[a].hashtag}
                                            hidden={ `${(hashtagObject[a].hidden == true) ? true : false}`}
                                            disabled={ `${(hashtagObject[a].disabled == true) ? true : false}`} />
                                        ))
                                    :null
                                }
                            </div>
                            <QaButtonWrapper>
                                <QaButton onClick={() => {handleStep('start')} }>{<i className="fa-solid fa-circle-left"></i>}</QaButton>
                                <QaButton onClick={() => {handleStep('chooseShop')} }>{<i className="fa-solid fa-circle-right"></i>}</QaButton>
                            </QaButtonWrapper>
                        </>
                    :null
                }
                {
                    (step == 'chooseShop') ?
                        <>
                            <div className="QaTitle"><GetText textName={'shopChoose'} /></div>
                            <div className="QaText">{title}</div>
                            <div className='QaHashtagWrapper'>
                                {
                                    (shopObject) ?
                                        Object.keys(shopObject).map((a) => (
                                            <Hashtag key={shopObject[a].shopId}
                                            active={ `${(shopObject[a].active == true) ? true : false}`}
                                            name={a} onClick={handleShopClick}
                                            text={shopObject[a].shop}
                                            hidden={ `${(shopObject[a].hidden == true) ? true : false}`}
                                            disabled={ `${(shopObject[a].disabled == true) ? true : false}`} />
                                        ))
                                    :null
                                }
                            </div>
                            <QaButtonWrapper>
                                <QaButton onClick={() => {handleStep('chooseHashtag')} }>{<i className="fa-solid fa-circle-left"></i>}</QaButton>
                                <QaButton onClick={() => {handleStep('chooseUnit')} }>{<i className="fa-solid fa-circle-right"></i>}</QaButton>
                            </QaButtonWrapper>
                        </>
                    :null
                }
                {
                    (step == 'chooseUnit') ?
                        <>
                            <div className="QaTitle"><GetText textName={'unitChoose'} /></div>
                            <div className="QaText">{title}</div>
                            <div className='QaHashtagWrapper'>
                                {
                                    (unitObject) ?
                                        Object.keys(unitObject).map((a) => (
                                            <Hashtag key={unitObject[a].unitId}
                                            active={ `${(unitObject[a].active == true) ? true : false}`}
                                            name={a} onClick={handleUnitClick}
                                            text={unitObject[a].unit}
                                            hidden={ `${(unitObject[a].hidden == true) ? true : false}`}
                                            disabled={ `${(unitObject[a].disabled == true) ? true : false}`} />
                                        ))
                                    :null
                                }
                            </div>
                            <QaButtonWrapper>
                                <QaButton onClick={() => {handleStep('chooseShop')} }>{<i className="fa-solid fa-circle-left"></i>}</QaButton>
                                <QaButton onClick={() => {handleStep('lastPreview')} }>{<i className="fa-solid fa-circle-right"></i>}</QaButton>
                            </QaButtonWrapper>
                        </>
                    :null
                }
                {
                    (step == 'lastPreview') ?
                        <>
                            <div className="QaTitle"><GetText textName={'qaPreview'} /></div>
                            <div className="QaText">{title}</div>
                            <div className='QaHashtagWrapper'>
                                {
                                    /* Show Selected Hashtags */
                                        (hashtagObject) ?
                                            Object.keys(hashtagObject).map((a) => (
                                                (hashtagObject[a].active == true) ?
                                                    <Hashtag key={hashtagObject[a].hashtagId}
                                                    active={ `${(hashtagObject[a].active == true) ? true : false}`}
                                                    name={a} onClick={handleCategoryClick}
                                                    text={hashtagObject[a].hashtag}
                                                    hidden={ `${(hashtagObject[a].hidden == true) ? true : false}`}
                                                    disabled={ `${(hashtagObject[a].disabled == true) ? true : false}`} />
                                                :null
                                            ))
                                        :null
                                    /* Show Selected Hashtags END */
                                }
                            </div>
                            <div className='QaShopWrapper'>
                                {
                                    /* Show Selected Shops */
                                        (shopObject) ?
                                            Object.keys(shopObject).map((a) => (
                                                (shopObject[a].active == true) ?
                                                    <Hashtag key={shopObject[a].shopId}
                                                    active={ `${(shopObject[a].active == true) ? true : false}`}
                                                    name={a} onClick={handleShopClick}
                                                    text={shopObject[a].shop}
                                                    hidden={ `${(shopObject[a].hidden == true) ? true : false}`}
                                                    disabled={ `${(shopObject[a].disabled == true) ? true : false}`} />
                                                :null
                                            ))
                                        :null
                                    /* Show Selected Shops END */
                                }
                            </div>
                            <div className='QaUnitWrapper'>
                                {
                                    /* Show Selected Units */
                                        (unitObject) ?
                                            Object.keys(unitObject).map((a) => (
                                                (unitObject[a].active == true) ?
                                                    <Hashtag key={unitObject[a].unitId}
                                                    active={ `${(unitObject[a].active == true) ? true : false}`}
                                                    name={a} onClick={handleUnitClick}
                                                    text={unitObject[a].unit}
                                                    hidden={ `${(unitObject[a].hidden == true) ? true : false}`}
                                                    disabled={ `${(unitObject[a].disabled == true) ? true : false}`} />
                                                :null
                                            ))
                                        :null
                                    /* Show Selected Units END */
                                }
                            </div>
                            <QaButtonWrapper>
                                <QaButton onClick={() => {handleStep('chooseUnit')} }>{<i className="fa-solid fa-circle-left"></i>}</QaButton>
                                <QaButton onClick={handleSubmitPost}>{<i className="fa-solid fa-circle-right"></i>}</QaButton>
                            </QaButtonWrapper>
                        </>
                    :null
                }
            </QaContent>
        </QaWrapper>
    )
};

export default CreatePost;