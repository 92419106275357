import React from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
// Config
import { IMG_URL } from '../../config'
//Components
import Spinner from '../Spinner';
import Button from '../Button';
import Vote from '../Vote';
import Moderation from '../Moderation';
import GetText from '../GetText';
import Hashtag from '../Hashtag';
// Styles
import { Wrapper as PostInfoWrapper, SubHashtag, MainHashtag, HashtagWrapper, Product } from './PostInfo.styles';

const PostInfo = ({ handleLikeHashtag, subHashtag, id, name, value, text, active, hidden, disabled, style,
                    showHashtag, postData, userHash, onClick, imageFullFileName }) => {

    const {
        loading,
        error
    } = useParams();
    
    const navigate = useNavigate();

    if(loading) return <Spinner />
    if(error) return <div>Something went wrong...</div>

if(handleLikeHashtag === true) {

    var id = id;
    var title = name;
    var text = null;
    var answerId = null;
    var answer = null;
    var answerDateDMY = null;
    var url = null;
    var imageFullFileName = imageFullFileName;

}
else
{
    var id = postData.id;
    var title = postData.title;
    var text = postData.text;
    var answerId = postData.answerId;
    var answer = postData.answer;
    var answerDateDMY = postData.answerDateDMY;
    var unit = postData.unit;
    var url = postData.url;
    var imageFullFileName = postData.imageFullFileName;
}
    return (
        <>
            {
            (handleLikeHashtag) ?
                (subHashtag !== true) ?
                    <PostInfoWrapper onClick={onClick}>
                    {

                        (id) ? (
                            <MainHashtag>
                                <article key={id}>
                                    <div className="body">
                                        <h1 id="title" className={`title`}>
                                            {title}
                                        </h1>
                                        <div id="text" className="text">
                                            <p>{text}</p>
                                        </div>
                                        <img className='image' alt={title} src={ IMG_URL + imageFullFileName } />
                                    </div>
                                </article>
                            </MainHashtag>
                        )
                        :
                        (
                            <></>
                        )
                    }
                </PostInfoWrapper>
                :
                <PostInfoWrapper onClick={onClick}>
                {

                    (id) ? (
                        <SubHashtag>
                            <article key={id}>
                                    <div className="body">
                                        <h1 id="title" className={`title`}>
                                            {title}
                                        </h1>
                                    </div>
                                </article>
                        </SubHashtag>
                    )
                    :
                    (
                        <></>
                    )
                }
            </PostInfoWrapper>
            :
                <PostInfoWrapper onClick={onClick}>
                    {
                        (id) ? (
                            <Product>
                                <article key={id}>
                                    <div className="post-wrapper post-id box-shadow-1">
                                        <div className="header">
                                            <img className='imageShop' alt={title} src={ 'https://upload.wikimedia.org/wikipedia/commons/thumb/4/44/Kaufland_201x_logo.svg/1024px-Kaufland_201x_logo.svg.png' } />
                                            <div className='moderation'>
                                                <Moderation paramPostId={id} paramAnswerId={answerId} paramUserHash={userHash} />
                                            </div>
                                        </div>
                                        <div className="body">
                                                <img className='image' alt={title} src={ IMG_URL + imageFullFileName } />
                                                <div id="price" className="price">
                                                    {
                                                        (answerId && answer && unit) ? (
                                                            <>
                                                                <p>{answer}&euro; / {unit}</p>
                                                            </>
                                                        )
                                                        :
                                                        (
                                                            <i><GetText textName='answerNotFound' /></i>
                                                        )
                                                    }
                                                </div>
                                                <h1 id="title" className="title">
                                                    {title}
                                                </h1>
                                                <div id="text" className="text">
                                                    <p>{text}</p>
                                                </div>
                                                {
                                                    (answerId && answer && unit) ? (
                                                        <>
                                                            <p className='date'>
                                                                <i className="fa-solid fa-clock icon"></i> {answerDateDMY}
                                                            </p>
                                                        </>
                                                    )
                                                    :
                                                    (
                                                        <></>
                                                    )
                                                }
                                                
                                        </div>
                                        <div id="footer">
                                            <div id="postAnswerVote" className="post-answer-vote">
                                                <Vote answerId={answerId} userHash={userHash} />
                                            </div>
                                        </div>

                                    </div>
                                </article>
                            </Product>
                        )
                        :
                        (
                            <></>
                        )
                    }
                    <HashtagWrapper>
                    {
                        (postData.hashtags && showHashtag == true) ?
                            Object.keys(postData.hashtags).map((a) => (
                                <>
                                    <Hashtag key={postData.hashtags[a].hashtagId}
                                    text={postData.hashtags[a].hashtag}
                                    />
                                </>
                            ))
                        :null
                    }
                    </HashtagWrapper>
                </PostInfoWrapper>
            }
        </>
    )
};

export default PostInfo;