import React, { useState } from 'react';
import { useNavigate, Link, useParams } from 'react-router-dom';
//Package
import disableScroll from 'disable-scroll';
import {isMobile} from 'react-device-detect';
//API
import API from './../API';
//Config
import { IMG_URL } from '../config';
//Functions
import UserFunctions from './UserFunctions';
// Components
import Header from './Header';
import Wrapper from './Wrapper'
import Footer from './Footer';
import Spinner from './Spinner';
import Moderation from './Moderation';
import IncrementCounter from './IncrementCounter';
//Styles
import { Wrapper as ListWrapper, ListNavigation, ListNavigationTab, ListSubNavigation, ListContent, ListEntry, ListEntryWrapper, ListEntryImage, ListFooter } from './List/List.styles';
//Hook
import { useShopsFetch } from '../hooks/useShopsFetch';
import { useListFetch } from '../hooks/useListFetch';
import { useListEntryFetch } from '../hooks/useListEntryFetch';
import Button from './Button';
import GetText from './GetText';

const List = () => {


    const [listNavigationTabId, setListNavigationTab] = useState(null); // List Id
    const [listEntryTab, setListEntryTab] = useState(''); // Entry Id

    const [handleList, setHandleList] = useState(''); // Choose what to do
    const [handleListBox, setHandleListBox] = useState(false); // Show to do Box

    const [handleListAction, setHandleListAction] = useState('');
    const [handleListName, setHandleListName] = useState('');
    const [handleListShop, setHandleListShop] = useState('');
    const [handleListCode, setHandleListCode] = useState('');
    const [handleListSubmitLoading, setHandleListSubmitLoading] = useState(false);
    const [handleListSubmitError, setHandleListSubmitError] = useState(false);
    
    const handleListInput = e => {
        const name = e.currentTarget.name;
        const value = e.currentTarget.value;

        if(name === 'handleCreateListName') setHandleListName(value);
        if(name === 'handleCreateListShop') setHandleListShop(value);
        if(name === 'handleRenameListName') setHandleListName(value);
        if(name === 'handleListCode') setHandleListCode(value);
        
    };

    /* Get Selected List Data */
    const {
        state: stateList,
        loading: listLoading,
        setReload: setReloadList,
        setListId: setSelectedListId,
        listId: selectedListId
    } = useListFetch(UserFunctions.getSessionHash());

    if(selectedListId != listNavigationTabId) {
        setSelectedListId(listNavigationTabId);
        setReloadList();
    }

    if(stateList.results[0]) {
        var listData = stateList.results;
    }
    /* Get Selected List Data END */

    /* Get Lists */
    const {
        state: stateLists,
        loading: listsLoading,
        setReload: setReloadLists
    } = useListFetch(UserFunctions.getSessionHash());

    if(stateLists.results[0]) {
        var lists = stateLists.results;
    }
    /* Get Lists End */

    /* Create / Delete / Rename / Share List */
    if(handleList === 'create') {
        //Create New List
            //Show Box
            if(handleListBox !== true) {
                setHandleListBox(true);
            }
    } else if(handleList === 'join') {
        //Join List
            //Show Box
            if(handleListBox !== true) {
                setHandleListBox(true);
            }
    } else if(handleList === 'leave') {
        //Leave List
            //Show Box
            if(handleListBox !== true) {
                setHandleListBox(true);
            }
    } else if(handleList === 'settings') {
        //Settings List
            //Show Box
            if(handleListBox !== true) {
                setHandleListBox(true);
            }
    } else if(handleList === 'back') {
        //back from list
            //Show Box
            if(handleList !== null) {
                setHandleList(null);//Reset because we are in home from List and didn't select anything
            }
            if(listNavigationTabId !== null) {
                setListNavigationTab(null); // Reset because we are in home from List and didn't select anything
            }

    } else if (handleList ==='delete') {
        if(listNavigationTabId != null) {
            //Delete Selected list
                //Show Box
                if(handleListBox !== true) {
                    setSelectedListId(null);
                    setHandleListBox(true);
                }
        }
    } else if (handleList === 'rename') {
        if(listNavigationTabId != null) {
            //Rename Selected List

                //Show Box
                if(handleListBox !== true) {
                    setSelectedListId(listNavigationTabId);
                    setReloadList();
                    setHandleListBox(true);
                }
        }
    } else if (handleList === 'share') {
        if(listNavigationTabId != null) {
            //Share Selected List
                //Show Box
                if(handleListBox !== true) {
                    setSelectedListId(listNavigationTabId);
                    setReloadList();
                    setHandleListBox(true);
                }
        }
    }
    else
    {
        //Turn off and hide box
        if(handleListBox === true) {
            setHandleListBox(false);
        }
    }


        /* API Stuff */
        const handleListSubmit = async () => {
            
            if(handleList) {
                try {
                    setHandleListSubmitLoading(true)
                    setHandleListSubmitError(false);

                    const data = await API.handleList(handleList, listNavigationTabId, handleListName, handleListShop, handleListCode, UserFunctions.getSessionHash());
            
                    var entryListId = data.results[0].entryListId;
                    var entryListValue = data.results[0].entryListValue;
                    var success = data.results[0].success;

                    if(success == true) {
                        setHandleList(null);
                        setReloadLists(true);
                        setReload(true);
                    }

                    setHandleListSubmitLoading(false)
                    setHandleListSubmitError(false);

                } catch (error) {
                    setHandleListSubmitLoading(false)
                    setHandleListSubmitError(true);
                }
            }
            
        };
        /* API Stuff End */
    /* Create / Delete / Rename / Share List */

    /* Send IncrementCounter */
    const handleGetNumber = async (entryId,number) => {
        /* API STUFF */
        
        if(entryId && number) {
            try {

                const data = await API.handleEntry('handleEntryAmount', entryId, null, number, UserFunctions.getSessionHash());
        
                var entryId = data.results[0].entryId;
                var entry = data.results[0].entry;
                var entryAmount = data.results[0].entryAmount;
                setReloadList();
            } catch (error) {

            }
        }
        
        /* API STUFF END */
    };
    /* Send Increment Count End */

    /* Get Shops */
    const {
        state: stateShops,
        loading: shopsLoading
    } = useShopsFetch();

    if(stateShops.results[0]) {
        var shops = stateShops.results;
    }
    /* Get Shops End */

    /* Get Entries of List */
    const {
        state: stateListEntry,
        loading: listEntryLoading,
        listId,
        setListId,
        setReload
    } = useListEntryFetch(UserFunctions.getSessionHash());

    if(listNavigationTabId != listId) {
        setListId(listNavigationTabId);
    }

    if(stateListEntry.results[0]) {
        var listEntry = stateListEntry.results;
    }
    /* Get Entries of List End */

    /* Update Entrie */
    const [inputEntryLoading, setInputEntryLoading] = useState(false);
    const [inputEntryError, setInputEntryError] = useState(false);
    const [inputEntry, setInputEntry] = useState('');

    const handleInputEntry = async (e) => {
        const getEntryId = e.currentTarget.name;
        const getEntry = e.currentTarget.value;
        const getEntryPlaceholder = e.currentTarget.placeholder;


            if(listEntryTab == getEntryId) {
                setInputEntry(getEntry);
            }


        if((getEntry != null) && (getEntryId != null)) {

            try {
                setInputEntryLoading(true)
                setInputEntryError(false);
        
                const data = await API.handleEntry('handleEntryValue', getEntryId, getEntry, null, UserFunctions.getSessionHash());
        
                var entryId = data.results[0].entryId;
                var entry = data.results[0].entry;

                setInputEntry(entry);
                setInputEntryLoading(false);
                setReload(true);

            } catch (error) {
                setInputEntryLoading(false);
                setInputEntryError(true);
            }
        }
        
    };
    /* Update Entrie End */

    return (
        <>
            <Header userName={UserFunctions.getUserName()} />
            <Wrapper>
                <ListWrapper>
                        {
                            (listNavigationTabId) ?
                                <div className='headerWrapper'>
                                    <div className={(handleList === 'back') ? `headerButton left listNavigationTabActive` : `headerButton left`} onClick={() => setHandleList('back')}>{<i className="fa-solid fa-arrow-left"></i>}</div>
                                    <div className={(handleList === 'leave') ? `headerButton right listNavigationTabActive` : `headerButton right`} onClick={() => setHandleList('settings')}>{<i className="fa-solid fa-gear"></i>}</div>
                                </div>
                            :
                                <></>
                        }
                    <ListNavigation>
                        {
                            (listsLoading) ?
                                <Spinner/>
                            :

                                (lists) ?
                                    Object.keys(lists).map((a) => (
                                        <ListNavigationTab key={a} className={(listNavigationTabId == lists[a].entryListId) ? `listNavigationTabActive` :null} onClick={() => setListNavigationTab(lists[a].entryListId)}>
                                            {<img className='image' alt={''} src={ IMG_URL+lists[a].imageFullFileName } />}
                                            {lists[a].entryListName}
                                        </ListNavigationTab>
                                    ))
                                :null
                        }
                    </ListNavigation>
                    {
                        /* Sub Navigation */
                        (listNavigationTabId) ?
                            <ListSubNavigation>

                            </ListSubNavigation>
                        :null
                        /* Sub Navigation End */
                    }

                    {
                        /* Pop Up Box */
                        (handleListBox) ?
                            <div className='handleListBox'>
                                <div className='handeListBoxContent'>
                                    {
                                        (listLoading) ? 
                                            <Spinner />
                                        :

                                        <>
                                            {
                                                (handleList === 'create') ?
                                                    <>
                                                        <div className='handeListBoxContentTitle'>Create</div>
                                                        <div>
                                                            Name:
                                                            <input className='handleListBoxInput' type='text' name='handleCreateListName' value={handleListName} onChange={handleListInput} />
                                                        </div>
                                                        <div>
                                                            Shop:
                                                            {
                                                                (shops) ?
                                                                    <select name='handleCreateListShop' onChange={handleListInput}>
                                                                        <option value="" disabled selected>Select a Shop</option>
                                                                    {
                                                                        Object.keys(shops).map((a) => (
                                                                            (shops[a].hidden != 1) ?
                                                                                <option value={shops[a].shopId}>{shops[a].shop}</option>
                                                                            :null
                                                                        ))
                                                                    }
                                                                    </select>
                                                                :null
                                                            }
                                                        </div>
                        
                                                        <Button className='handleListBoxButton' callback={handleListSubmit} text={<GetText textName={'create'} />}/>
                                                    </>
                                                :null
                                            }
                                            {
                                                (handleList === 'join') ?
                                                    <>
                                                        <div className='handeListBoxContentTitle'><GetText textName={'join'} /></div>
                                                        <div>
                                                            Code:
                                                            <input className='handleListBoxInput' type='text' name='handleListCode' value={handleListCode} onChange={handleListInput} />
                                                        </div>
                                                        <Button className='handleListBoxButton' callback={handleListSubmit} text={<GetText textName={'join'} />}/>
                                                    </>
                                                :null
                                            }
                                            {
                                                (handleList === 'leave') ?
                                                    <>
                                                        <div className='handeListBoxContentTitle'><GetText textName={'leave'} /></div>
                                                        <Button className='handleListBoxButton' callback={handleListSubmit} text={<GetText textName={'leave'} />}/>
                                                    </>
                                                :null
                                            }
                                            {
                                                (handleList === 'settings') ?
                                                    <>
                                                        <div className='handeListBoxContentTitle'><GetText textName={'settings'} /></div>
                                                        {
                                                            (listData[0].entryListGroupPermissions == 1) ?
                                                            <center>
                                                                <button className={'handeListBoxContentSettingsButton'} onClick={() => setHandleList('rename')}>{<i className="fa-solid fa-pen-to-square"></i>}</button>
                                                                <button className={'handeListBoxContentSettingsButton'} onClick={() => setHandleList('share')}>{<i className="fa-solid fa-arrow-up-from-bracket"></i>}</button>
                                                                <button className={'handeListBoxContentSettingsButton'} onClick={() => setHandleList('delete')}>{<i className="fa-solid fa-trash"></i>}</button>
                                                            </center>
                                                        :
                                                            <></>
                                                        }
                                                    </>
                                                :null
                                            }
                                            {
                                                (handleList === 'rename') ?
                                                    <>
                                                        <div className='handeListBoxContentTitle'>Rename</div>
                                                        <input className='handleListBoxInput' type='text' name='handleRenameListName' value={handleListName} placeholder={listData[0].entryListName} onChange={handleListInput} />
                                                        <Button className='handleListBoxButton' callback={handleListSubmit} text={<GetText textName={'update'} />}/>
                                                    </>
                                                :null
                                            }
                                            {
                                                (handleList === 'share') ?
                                                    <>
                                                        <div className='handeListBoxContentTitle'>Share</div>
                                                        <p>Admin: <span>{(listData[0].entryListCodeWithPermissions) ? listData[0].entryListCodeWithPermissions : ''}</span></p>
                                                        <p>View only: <span>{(listData[0].entryListCodeNoPermissions) ? listData[0].entryListCodeNoPermissions : ''}</span></p>
                                                    </>
                                                :null
                                            }
                                            {
                                                (handleList === 'delete') ?
                                                    <>
                                                        <div className='handeListBoxContentTitle'><GetText textName={'delete'} /></div>
                                                        <Button className='handleListBoxButton' callback={handleListSubmit} text={<GetText textName={'delete'} />}/>
                                                    </>
                                                :null
                                            }
                                        </>
                                    }
                                    <Button className={'dismissButton'} callback={() => setHandleList(null) } text={<GetText textName={'dismiss'} />}/>
                                </div>
                            </div>
                        :null
                        /* Pop Up Box End */
                    }
                    <ListContent>
                        {
                            (listNavigationTabId === null) ?
                                <div className='buttonWrapper'>
                                    <Button className={(handleList === 'create') ? `button listNavigationTabActive` : `button`} callback={() => setHandleList('create')} text={<GetText textName={'create'} />}></Button>
                                    <Button className={(handleList === 'join') ? `button listNavigationTabActive` : `button`} callback={() => setHandleList('join')} text={<GetText textName={'join'} />}></Button>
                                </div>
                            :null
                        }
                        {
                            /* Entries */
                            (listEntryLoading) ?
                                <Spinner/>
                            :
                                (listEntry) ?

                                    Object.keys(listEntry).map((a) => (
                                        <ListEntryWrapper key={a}>
                                            <ListEntry
                                                key={a}
                                                type={'text'}
                                                name={listEntry[a].entryId}
                                                className={(listEntryTab == listEntry[a].entryId) ? `listEntryActive` :null}
                                                onClick={() => setListEntryTab(listEntry[a].entryId)}
                                                onChange={handleInputEntry}>
                                                
                                                <ListEntryImage alt={listEntry[a].postTitle} src={ IMG_URL + listEntry[a].imageFullFileName }/>
                                                <div className='entryTextWrapper'>
                                                    <span className='title'>{listEntry[a].postTitle}</span>
                                                    <span className='shop'>{listEntry[a].postShop}</span>
                                                    <span className='price'>{listEntry[a].postPrice}{listEntry[a].postPriceCurrency}</span>
                                                    <span className='dropDown'>
                                                        <Moderation paramUserHash={UserFunctions.getSessionHash()} paramEntryId={listEntry[a].entryId} />
                                                    </span>
                                                </div>
                                                <div className='amountWrapper'>
                                                    <IncrementCounter min={1} vertical={true} value={listEntry[a].entryAmount} getNumber={(number) => handleGetNumber(listEntry[a].entryId,number)} />
                                                </div>
                                            </ListEntry>
                                        </ListEntryWrapper>
                                    ))
                                :null
                            /* Entries End */
                        }
                    </ListContent>
                    {
                        /* Footer */
                        (listNavigationTabId) ?
                            <ListFooter>
                                <>
                                    <button className='button'>

                                        <span className='totalPriceTitle'>Total</span>

                                        <span className='totalPriceAmount'>
                                            {
                                                (listLoading) ?
                                                    'Loading'
                                                :
                                                    listData[0].totalPriceFormat + listData[0].totalPriceCurrency
                                            }
                                        </span>

                                        <span className='totalNext'>Next</span>

                                    </button>
                                </>
                            </ListFooter>
                        :null
                        /* Footer End */
                    }
                </ListWrapper>
            </Wrapper>
            {
                (!isMobile) ? <Footer /> :null
            }
        </>
    )
};

export default List;