// Configuration for TMDB API
// Read more about the API here: https://developers.themoviedb.org/

const API_URL = 'https://shoppingstructure.report4feed.com/api/';
const IMG_URL = 'https://shoppingstructure.report4feed.com/api/uploads/';
const USER_IMG_URL = 'https://shoppingstructure.report4feed.com/api/uploads/';
//const API_KEY = process.env.REACT_APP_API_KEY;
//const API_KEY = '';

const SEARCH_BASE_URL = `${API_URL}search?language=en-US&query=`;
const POSTS_BASE_URL = `${API_URL}posts?language=en-US`;

const IMAGE_BASE_URL = 'http://image.tmdb.org/t/p/';
// Sizes: w300, w780, w1280, original
const BACKDROP_SIZE = 'w1280';
// w92, w154, w185, w342, w500, w780, original
const POSTER_SIZE = 'w780';

export {
  SEARCH_BASE_URL,
  POSTS_BASE_URL,
  IMG_URL,
  API_URL,
  USER_IMG_URL,
  //API_KEY,
  IMAGE_BASE_URL,
  BACKDROP_SIZE,
  POSTER_SIZE,
};
