import { useState, useEffect } from 'react';
// API
import API from '../API';

const initialState = {
    results: []
};

export const useListFetch = (userHash) => {
    const [state, setState] = useState(initialState);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);

    const [reload, setReload] = useState(false);
    const [listId, setListId] = useState(null);

    useEffect(() => {
        const fetchList = async() => {
            try {
                setLoading(true);
                setError(false);

                const data = await API.fetchList(listId, userHash);
            
                setState({
                    ...data,
                    results: [...data.results]
                })

            } catch (error) {
                setError(true);
            }
            setLoading(false);
            setReload(false);
        };

        fetchList();
    }, [userHash, reload])

    return { state, loading, error, setReload, setListId, listId };
}