import { useState, useEffect } from 'react';
// API
import API from '../API';

const initialState = {
    results: []
};

export const useShopsFetch = () => {
    const [state, setShops] = useState(initialState);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);

    const [shopSelectedName, setShopSelectedName] = useState('');

    const fetchShops = async() => {
        try {
            setLoading(true);
            setError(false);

            const data = await API.fetchShops();

            setShops({
                ...data,
                results: [...data.results]
            });

            setLoading(false);

        } catch (error) {
            setLoading(false);
            setError(true);
        }
    };
        

    useEffect(() => {
        fetchShops(shopSelectedName);
    }, [shopSelectedName])

    return { state, loading, error,
        shopSelectedName, setShopSelectedName};
}