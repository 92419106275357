import { useState, useEffect } from 'react';
// API
import API from '../API';

const initialState = {
    results: []
};

export const useInventoryFetch = (userHash) => {
    const [state, setState] = useState(initialState);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);

    useEffect(() => {
        const fetchInventory = async() => {
            try {
                setLoading(true);
                setError(false);

                const data = await API.fetchInventory(userHash);
                
                setState({
                    ...data,
                    results: [...data.results]
                })

                setLoading(false);

            } catch (error) {
                setError(true);
            }
        };

        fetchInventory();
    }, [userHash])

    return { state, loading, error };
}