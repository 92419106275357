import { useState, useEffect } from 'react';
// API
import API from '../API';
//Functions
import UserFunctions from '../components/UserFunctions';

const initialState = {
    page: 0,
    results: [],
};

export const usePostsFetch = (startPage) => {
    const [searchFilter, setSearchFilter] = useState(null);
    const [topUsers, setTopUsers] = useState('');
    const [state, setState] = useState(initialState);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [isLoadingMore, setIsLoadingMore] = useState(false);
    const [canLoadMore, setCanLoadMore] = useState(true);
    const [scrollUp, setScrollUp] = useState(false);

    const fetchPosts = async (page, searchFilter) => {
        try {
            setError(false);
            setLoading(true);

            if(searchFilter != null) {
                const posts = await API.fetchPosts(page, searchFilter);
                //console.log('page:' + page);

                /* Stats */
                const getStats = await API.fetchStats(UserFunctions.getSessionHash());
                setTopUsers(getStats.results[0].topUsers);
                /* Stats End */

                if(posts.results) {

                    if(scrollUp == true) {

                        setState(prev => ({
                            ...posts,
                            results: page >= 0 ? [...posts.results, ...prev.results] : [...posts.results]
                        }))
                    }
                    else
                    {

                        setState(prev => ({
                            ...posts,
                            results: page >= 0 ? [...prev.results, ...posts.results] : [...posts.results]
                        }))

                    }
                }
                else
                {
                    setCanLoadMore(false);
                }
            }
        } catch (error) {
            setError(true);
        }
        setLoading(false);
    };

    // Search and initial
    useEffect(() => {
        setState(initialState);
        fetchPosts(startPage, searchFilter);
    }, [searchFilter]);

    // Load More
    useEffect(() => {

        if(!isLoadingMore) return;

        if(scrollUp == true) {
            
            //var newPage = parseInt(state.page) - 1;
            var newPage = parseInt(0);
        }
        else
        {
            var newPage = parseInt(state.page) + 1;
        }
      
        if(newPage < 0) {
            var newPage = parseInt(0);
        }

        if(!newPage) {
            var newPage = 0;
        }
    
        if(newPage == 0) {
            UserFunctions.deleteScrollPosts();
        }

        UserFunctions.setScrollPage(newPage);

        fetchPosts(newPage, searchFilter); //Get Post with new Page Number
        setIsLoadingMore(false);

    }, [scrollUp, isLoadingMore, searchFilter, state.page]);

    return { state, topUsers, loading, error, searchFilter, canLoadMore, scrollUp, setIsLoadingMore, setCanLoadMore, setSearchFilter, setScrollUp };
};