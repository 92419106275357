import styled from "styled-components";
//Mobile Check
import {isMobile} from 'react-device-detect';

export const Wrapper = styled.div`
width:100%;
margin:auto;
min-height:100vh;
margin-top: var(--headerHeight);

.searchResultsTitle {
    margin:auto;
    max-width: var(--maxWidth);
    color: var(--color-5);
    ${(isMobile) ? `text-align:center;` :null }
}

.hashtagWrapper {
    display: flex;
    overflow-x: scroll;
    box-shadow: var(--box-shadow-1);
}

.postWrapper {
    display: flex;
    overflow-x: scroll;
    box-shadow: var(--box-shadow-1);
}

.post-wrapper {
    margin: 12px auto;
    max-width:var(--superMediumWidth);
}

.homeNavigation {
    margin:auto;
    width:100%;
    max-width:var(--superMediumWidth);
    display: table;
}

.buttonNavigation {
    display: inline-block;
    text-align: center;
    margin: 10px;
    color: var(--color-2);
    padding: 12px;
    font-size: var(--fontSmall);
    border-radius:2px !important;

    .backButton {
        color: var(--color-1);
        font-size:30px;
    }
}

.topUsersButton {
    display: flex;
    float: left;
}

.rankingImage {
    width:40px;
    height:40px;
    border-radius:100%;
    border: 2px solid;
    border-color: var(--color-border-1);
    margin:10px;
    display:flex;
    float: left;
}
`;