import { useState, useEffect } from 'react';
// API
import API from '../API';

export const useUnitsFetch = () => {
    const [state, setUnits] = useState({});
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);

    const [unitSelectedName, setUnitSelectedName] = useState('');

    
    

    const fetchUnits = async() => {
        try {
            setLoading(true);
            setError(false);

            const data = await API.fetchUnits();

            setUnits({
                ...data,
            });

            setLoading(false);

        } catch (error) {
            setLoading(false);
            setError(true);
        }
    };
        

    useEffect(() => {
        fetchUnits(unitSelectedName);
    }, [unitSelectedName])

    return { state, loading, error,
        unitSelectedName, setUnitSelectedName};
}