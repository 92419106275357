import React, { useState } from 'react';
//Mobile Check
import {isMobile} from 'react-device-detect';
//Functions
import UserFunctions from './UserFunctions';
// Components
import Header from './Header';
import Wrapper from './Wrapper'
import Footer from './Footer';
//Styles
import { Wrapper as PlannerWrapper, PlannerNavigation, PlannerNavigationTab, PlannerContent, PlannerContentToday, PlannerContentWeek, PlannerContentMonth } from './Planner/Planner.styles';
//Hook
import { usePlannerFetch } from '../hooks/usePlannerFetch';
//Calendar
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';


const Planner = () => {

    const {
        state
    } = usePlannerFetch(UserFunctions.getSessionHash());

    const [plannerNavigationTab, setPlannerNavigationTab] = useState('Today');

    const [dateState, setDateState] = useState(new Date())
    const changeDate = (e) => {
      setDateState(e)
    }

    return (
        <>
            <Header userName={UserFunctions.getUserName()} />
            <Wrapper>
                <PlannerWrapper>
                    <PlannerNavigation>
                        <PlannerNavigationTab className={(plannerNavigationTab === 'Today') ? `active` :null} onClick={() => setPlannerNavigationTab('Today')}>Today</PlannerNavigationTab>
                        <PlannerNavigationTab className={(plannerNavigationTab === 'Week') ? `active` :null} onClick={() => setPlannerNavigationTab('Week')}>Week</PlannerNavigationTab>
                        <PlannerNavigationTab className={(plannerNavigationTab === 'Month') ? `active` :null} onClick={() => setPlannerNavigationTab('Month')}>Month</PlannerNavigationTab>
                    </PlannerNavigation>
                    <PlannerContent>
                        {
                            (plannerNavigationTab === 'Today') ?
                                <PlannerContentToday>
                                    <div className='row'>
                                        <div className='rowHead'>
                                            <div className='rowCircle'></div>
                                            <div className='rowTime'>08:00</div>
                                            <div className='rowTitle'>Frühstück</div>
                                        </div>
                                        <div className='rowBody'>
                                            <div className='rowTask'>Avocado mit Reis</div>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='rowHead'>
                                            <div className='rowCircle'></div>
                                            <div className='rowTime'>13:00</div>
                                            <div className='rowTitle'>Mittagessen</div>
                                        </div>
                                        <div className='rowBody'>
                                            <div className='rowTask'>Spaghetti mit Bolognese</div>
                                            <div className='rowTask'>Wackelpudding</div>
                                            <div className='rowTask'>Eis</div>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='rowHead'>
                                            <div className='rowCircle'></div>
                                            <div className='rowTime'>15:00</div>
                                            <div className='rowTitle'>Kaffee</div>
                                        </div>
                                        <div className='rowBody'>
                                            <div className='rowTask'>Kuchen</div>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='rowHead'>
                                            <div className='rowCircle'></div>
                                            <div className='rowTime'>20:00</div>
                                            <div className='rowTitle'>Abendessen</div>
                                        </div>
                                        <div className='rowBody'>
                                            <div className='rowTask'>Nudelauflauf</div>
                                        </div>
                                    </div>
    
                                </PlannerContentToday>
                            :null
                        }
                        {
                            (plannerNavigationTab === 'Week') ?
                                <PlannerContentWeek>
                                Week
                                </PlannerContentWeek>
                            :null
                        }
                        {
                            (plannerNavigationTab === 'Month') ?
                                <PlannerContentMonth>
                                    <>
                                    <Calendar 
                                        value={dateState}
                                        onChange={changeDate}
                                        />

                                        <p>Current selected date is <b>{dateState.getFullYear()+'-'+("0"+(dateState.getMonth()+1)).slice(-2)+'-'+("0"+dateState.getDate()).slice(-2)}</b></p>

                                    </>
                                </PlannerContentMonth>
                            :null
                        }


                    </PlannerContent>
                        {
                            state.results.map(row => (
                                <>

                                </>
                            ))
                        }


                </PlannerWrapper>
            </Wrapper>
            {
                (!isMobile) ? <Footer /> :null
            }
        </>
    )
};

export default Planner;