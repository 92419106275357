import { useState, useEffect } from 'react';
// API
import API from '../API';

const initialState = {
    results: []
};

export const useListEntryFetch = (userHash) => {
    const [state, setState] = useState(initialState);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);

    const [listId, setListId] = useState(null);
    const [reload, setReload] = useState(false);

    useEffect(() => {
        const fetchListEntry = async() => {
            try {
                setLoading(true);
                setError(false);

                const data = await API.fetchListEntry(listId, userHash);



                if(!data.results) {
                    setState({
                        ...data,
                        results: ''
                    })
                }
                else
                {
                    setState({
                        ...data,
                        results: [...data.results]
                    })
                }

            } catch (error) {
                setError(true);
            }
            setLoading(false);
            setReload(false);
        };

        fetchListEntry();
    }, [userHash, listId, reload])

    return { state, loading, error, listId, setListId, setReload };
}