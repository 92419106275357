import { React } from 'react';
import { useNavigate, Link, useParams } from 'react-router-dom';
//Mobile Check
import {isMobile} from 'react-device-detect';
// Components
import Spinner from './Spinner';
import PostInfo from './PostInfo';
import Header from './Header';
import Footer from './Footer'
//Styles
import { Wrapper as HomeWrapper } from './Home/Home.styles';
//Functions
import UserFunctions from './UserFunctions';
// Hook
import { usePostsFetch } from '../hooks/usePostsFetch';
import { useHashtagsFetch } from '../hooks/useHashtagsFetch';


const Home = () => {

    const {
        hashtag,
        search
    } = useParams();

    const navigate = useNavigate();


    /* Hashtag Stuff START */
    const {
        state: dataHashtags,
        loading: hashtagLoading,
        error: hashtagError,
        hashtagSelectedName,
        setHashtagSelectedName,
    } = useHashtagsFetch();



    const {
        state: dataHashtagsSub,
        loading: hashtagLoadingSub,
        error: hashtagErrorSub,
        hashtagSelectedName: hashtagSelectedNameSub,
        setHashtagSelectedName: setHashtagSelectedNameSub,
        subHashtag,
        setSubHashtag
    } = useHashtagsFetch();
    
    if(subHashtag != true) {
        setSubHashtag(true);
    }

    if(hashtag) { 
        if(hashtagSelectedNameSub != hashtag) {
            setHashtagSelectedNameSub(hashtag);
        }
    }
    else
    {
        if(hashtagSelectedNameSub) {
            setHashtagSelectedNameSub(null);
        }
    }





/* Hashtag Stuff END */

    const {
        state: dataPosts,
        loading, 
        error,
        searchFilter,
        canLoadMore,
        scrollUp,
        setSearchFilter,
        setIsLoadingMore,
        setCanLoadMore,
        setScrollUp,
    } = usePostsFetch(0);

    //if (error) return <div>Something went wrong ...</div>

    
    function postsLoad(value) {
        if(value) {
            if(searchFilter != value) {
                setSearchFilter(value);
            }
        }
        else
        {
            if(searchFilter != null) {
                setSearchFilter(null);
            }
        }
    }

    /* Sort Posts */
    const uniquePosts = Array.from(new Set(dataPosts.results.map(a => a.id)))
    .map(id => {
      return dataPosts.results.find(a => a.id === id)
    })
    /* Sort Posts End */

    //Load Posts when scrolling Horizontal Start
    if((document.getElementById('postWrapper') !== null) && (error === false)) {
        document.getElementById("postWrapper").onscroll = () => {
            horizontalScroll();
        }
        
    
    }
    
    function horizontalScroll() {
        if((document.getElementById('postWrapper') !== null) && (error === false)) {

            var hashtagWrapperWidth = document.getElementById("postWrapper").clientWidth;

            /* Horizontal Scroll */
            var horizontalScrollPosition = document.getElementById("postWrapper").scrollLeft;
            var horizontalScrollGoalOnePercent = ((hashtagWrapperWidth) / 100)
            var horizontalScrollGoal = (horizontalScrollGoalOnePercent * 75);


            /* Horizontal Scroll */

            /* Scroll Horizontal */
            if((horizontalScrollPosition >= horizontalScrollGoal) && (!loading) && (canLoadMore === true)) {
                setIsLoadingMore(true);
                setScrollUp(false); // Note: setScrollUp & setIsLoadingMore should load at the same time, Better -> first setScrollUp so we know we are scrolling up and then setIsLoadingMore
                                    // But if we call set ScrollUp First, the script runs two times and loads two time the same posts which cause an error. Should be fixed later
            }
            else
            {
                if(horizontalScrollPosition >= (horizontalScrollGoal)) {
                    setCanLoadMore(true);
                }
            }
        /* Scroll Horizontal End */
        }
    }
    //Load Posts when scrolling Horizontal END




    //Load Posts when vertical scrolling
function disabled() {
    document.getElementsByTagName('body')[0].onscroll = () => {

        if((document.getElementById('postWrapper') !== null) && (error === false)) {

            var windowPosition = document.documentElement.scrollTop;
            var homeWrapperHeight = document.getElementById("postWrapper").clientHeight;
            var headerHeight = document.getElementById("header").clientHeight;
            var windowHeight = window.innerHeight;

            if(isMobile) {
                var headerHeight = 1;
            }

            /* Vertical Scroll */
            var windowPosition = document.documentElement.scrollTop;
            var downScrollGoalOnePercent = ((homeWrapperHeight+headerHeight) / 100)
            var downScrollGoal = (downScrollGoalOnePercent * 98);

            var screenPositionTop = windowPosition;
            var screenPositionBottom = (windowPosition + windowHeight);
            /* Vertical Scroll */

        /* Scroll Down */
            if((screenPositionBottom >= downScrollGoal) && (!loading) && (canLoadMore === true)) {
                setIsLoadingMore(true);
                setScrollUp(false); // Note: setScrollUp & setIsLoadingMore should load at the same time, Better -> first setScrollUp so we know we are scrolling up and then setIsLoadingMore
                                    // But if we call set ScrollUp First, the script runs two times and loads two time the same posts which cause an error. Should be fixed later
            }
            else
            {
                if(screenPositionBottom >= (downScrollGoal / 2)) {
                    setCanLoadMore(true);
                }
            }
        /* Scroll Down End */
        }

	};
};




    return (
        <>
            <Header userName={UserFunctions.getUserName()} />
        
            <HomeWrapper id='homeWrapper'>
                {
                    /* LOGGED IN HEADER START PAGE */
                    (UserFunctions.getSessionHash()) ? (
                        <div className='homeNavigation'>
                            {
                                (searchFilter) ?
                                    /*<Link className='buttonNavigation hover' to={`/`} onClick={() => {setSearchFilter(null)}}>
                                        <i className="fa-solid fa-circle-left backButton "></i>
                                    </Link>*/
                                    <></>
                                :null
                            }
                           
                        </div>
                    )
                    :null
                    /* LOGGED IN HEADER START PAGE END */
                }
                {
                /* SEARCH && POST */
                <>
                    <>
                        {
                            //(hashtagSelectedName) ? 'hashtagSelectedName:' + hashtagSelectedName : <></> 
                        }
                    </>
                    <div className='hashtagWrapper'>
                        {
                            
                            /* Logged in */ 
                            (UserFunctions.getSessionHash()) ? (



                                    (!hashtagLoading) ?
                                        (dataHashtags) ?
                                            Object.keys(dataHashtags).map((a) => (
                                                <div onClick={() => { postsLoad(null) }}>
                                                    <PostInfo key={dataHashtags[a].hashtagId}
                                                    handleLikeHashtag={true}
                                                    userHash={UserFunctions.getSessionHash()}
                                                    id={dataHashtags[a].hashtagId}
                                                    name={dataHashtags[a].hashtag}
                                                    imageFullFileName={dataHashtags[a].imageFullFileName}
                                                    onClick={() => {navigate(`/!${encodeURIComponent(dataHashtags[a].hashtag)}`)}}
                                                    text={dataHashtags[a].hashtag}
                                                    hidden={ `${(dataHashtags[a].hidden == true) ? true : false}`}
                                                    disabled={ `${(dataHashtags[a].disabled == true) ? true : false}`} />
                                                </div>
                                            ))
                                        :null
                                    :null
                
                                
                            /* Logged in END */
                            ):null
                        }
                    </div>
                    <div id="hashtagWrapper" className='hashtagWrapper'>
                        {
                            
                            /* Logged in */ 
                            (UserFunctions.getSessionHash()) ? (


                                (hashtag) ?
                                    (!hashtagLoadingSub) ?
                                        (dataHashtagsSub) ?
                                            Object.keys(dataHashtagsSub).map((a) => (
                                                <div onClick={() => { postsLoad(encodeURIComponent(dataHashtagsSub[a].hashtag)) }}>
                                                    <PostInfo key={dataHashtagsSub[a].hashtagId}
                                                    handleLikeHashtag={true}
                                                    subHashtag={true}
                                                    userHash={UserFunctions.getSessionHash()}
                                                    id={dataHashtagsSub[a].hashtagId}
                                                    name={dataHashtagsSub[a].hashtag}
                                                    imageFullFileName={dataHashtagsSub[a].imageFullFileName}
                                                    text={dataHashtagsSub[a].hashtag}
                                                    hidden={ `${(dataHashtagsSub[a].hidden == true) ? true : false}`}
                                                    disabled={ `${(dataHashtagsSub[a].disabled == true) ? true : false}`} />
                                                </div>
                                            ))
                                        :null
                                    :null
                                :null
                                
                            /* Logged in END */
                            ):null
                        }
                    </div>
                    {
                        //(searchFilter) ? 'searchFilter:' + searchFilter : <></> 
                    }
                    {
                        ((uniquePosts != null) && (hashtag != null)) ?
                            //Load Products aka Post
                            <div id="postWrapper" className='postWrapper'>
                                {
                                    
                                    /* Logged in */ 
                                    (UserFunctions.getSessionHash()) ? (

                                            (!hashtagLoading) ?
                                                (uniquePosts) ?
                                                    Object.keys(uniquePosts).map((a) => (
                                                        <Link to={`/p/${uniquePosts[a].url}`}>
                                                        <PostInfo key={uniquePosts[a].id}
                                                        handleLikeHashtag={false}
                                                        userHash={UserFunctions.getSessionHash()}
                                                        postData={uniquePosts[a]} />
                                                        </Link>
                                                    ))
                                                :null
                                            :null
                        
                                        
                                    /* Logged in END */
                                    ):null
                                }
                            </div>
                        :null
                    }
                </>
                /* SEARCH && POST END */
                }
                {
                    loading ?
                        <Spinner hidden={false} />
                    :
                        <Spinner hidden={true} />
                }
            </HomeWrapper>
            {
                (!isMobile) ? <Footer /> :null
            }
        </>
    )
};
export default Home;