import styled from 'styled-components';

export const WrapperHorizontal = styled.div`
display:block;

.hashtagActive {
    color: var(--color-1) !important;
    background: var(--color-bg-2);
}
`

export const ButtonHorizontal = styled.button`
cursor:pointer;
text-align:center;
display:inline-block;
font-size: var(--fontSmall);
color: var(--color-5);
background: var(--color-bg-2);
height:35px;
width:35px;
border-radius: 100%;
box-shadow: var(--box-shadow-1);
border:none;
`

export const AmountHorizontal = styled.div`
margin:6px;
display:inline-block;
font-size: var(--fontSuperSmall);
color: var(--color-5);
background: var(--color-bg-2);
border-radius: 100%;
box-shadow: var(--box-shadow-1);
`


export const WrapperVertical = styled.div`
display:block;

.hashtagActive {
    color: var(--color-1) !important;
    background: var(--color-bg-2);
}
`

export const ButtonVertical = styled.button`
cursor:pointer;
text-align:center;
display:block;
font-size: var(--fontSmall);
color: var(--color-5);
background: var(--color-bg-2);
height:40px;
width:40px;
border-radius: 100%;
border:none;
`

export const AmountVertical = styled.button`
cursor:default;
text-align:center;
display:block;
font-size: var(--fontSmall);
color: var(--color-5);
background: var(--color-bg-2);
height:40px;
width:40px;
margin-top:10px;
margin-bottom:10px;
border-radius: 100%;
border:none;
`