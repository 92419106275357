import styled from "styled-components";

export const Wrapper = styled.div`
width:100%;
max-width: var(--superMediumWidth);
margin:auto;
margin-top:20px;
padding: var(--padding-left-right);

.listNavigationTabActive {
    border-bottom:4px solid;
    border-color:var(--color-border-1);
    color:var(--color-5);
}

.listEntryActive {
    
}

.handleListBox {
    overflow:hidden;
    position:fixed;
    top:25vh;
    left:5%;
    z-index:1000;
    width:90%;
    height:50vh;
    background: var(--color-bg-3);
    border:5px solid;
    border-radius:6%;

    .dismissButton {
        background:none;
    }
}

.handeListBoxContent {

}

.handeListBoxContentTitle {
    text-align:center;
    font-size:var(--fontBig);
}

.handleListBoxButton {
    background: var(--color-bg-1);
}

.handleListBoxInput {
    font-size:var(--fontSmall);
    background: var(--color-bg-2);
    border:1px solid;
    border-color: var(--color-border-4);
}

.handeListBoxContentSettingsButton {
    background: var(--color-bg-6);
    color:white;
    font-size:var(--fontMed);
    border:none; 
    display:inline-block;
    margin:10px;
}

.headerWrapper {
    width:100%;
    display: inline-block;

    .left {
        float:left;
    }
    .right {
        float:right;
    }
}

.headerButton {
    text-align:center;
    width:max-content;
    font-size:var(--fontBig);
}

.buttonWrapper {
    margin-left:10%;
    width:80%;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(50px, 1fr));
    grid-gap: 0%;
    grid-auto-flow: row;
    text-align:center;
}

.button {

}
`;

export const ListNavigation = styled.div`
font-size:var(--fontMed);
display:block;
`;

export const ListSubNavigation = styled.div`
display: grid;
grid-template-columns: repeat(auto-fit, minmax(50px, 1fr));
grid-gap: 0%;
grid-auto-flow: row;
font-size:var(--fontMed);
`;

export const ListNavigationTab = styled.div`
width:100%;
padding:var(--padding-top-bottom);
border-bottom:4px solid;
border-color: var(--color-border-4);
text-align:left;
background:var(--color-bg-0);
color:var(--color-4);

    img {
        width:25px;
        height:25px;
    }
`;

export const ListContent = styled.div`
width:100%;
max-width: var(--superMediumWidth);
margin:auto;
padding-top:20px;
padding-bottom:20px;
display: grid;
grid-template-columns: 100%;
grid-gap: 0%;
grid-auto-flow: row;
`;

export const ListEntryWrapper = styled.div`
width:100%;
padding:var(--padding-all);
text-align:center;
background:var(--color-bg-2);
color:var(--color-5);
`;

export const ListEntry = styled.div`
float:left;
width:100%;
heigt:100%;
text-align:center;
background:var(--color-bg-2);
color:var(--color-5);

.entryTextWrapper {
    float:left;
    width:55%;
    margin-left:5%;
}
.title {
    text-align:left;
    display:block;
    font-size:var(--fontSmall);
    color:var(--color-5);
}
.shop {
    text-align:left;
    display:block;
    font-size:var(--fontSuperSmall);
    color:var(--color-4);
    margin-top:5px;
}
.price {
    text-align:left;
    display:block;
    font-size:var(--fontMed);
    color:var(--color-1);
    margin-top:5px;
}

.amountWrapper {
    width:10%;
    float:left;
}

.dropDown {
float:left;
display:block;
width:35px;
margin-top:5px;
}
`;

export const ListEntryImage = styled.img`
float:left;
width:30%;
border-radius:12px;
`;

export const ListFooter = styled.div`
width: 100%;
max-width: var(--superMediumWidth);
margin: auto;
padding-bottom: 40px;
display: block;


    .button {
        padding: var(--padding-all);
        width: 100%;
        max-width:250px;
        margin: auto;
        margin-top:5px;
        background: var(--color-bg-1);
        border-radius:50px;
        border:none;
        color:var(--color-2);
        display:block;
    }

    .totalPriceTitle {
        text-align:left;
        display:inline-block;
        float:left;
        margin: var(--margin-left-right);
        font-size: var(--fontSmall);
    }
    .totalPriceAmount {
        text-align:left;
        float:left;
        font-size: var(--fontMed);
        font-weight:700;
    }
    .totalNext {
        text-align:right;
        display:inline-block;
        float:right;
        margin: var(--margin-left-right);
        font-size: var(--fontSmall);
    }
`;